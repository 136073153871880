import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import enrichActionWithCustomAttributes from '#/features/logging/datadog/enrichActionWithCustomAttributes';

import {
  getErrorMessage,
  getErrorMessageWithCauses,
  getRootCause,
} from '#/utils/error';
import { Nullable } from '#/utils/types';
import { getAppViewType } from '#/utils/userAgent';

import { enrichPostOrderReq } from './datadog/enrichRequest';

const SESSION_RECORDING_ENVS = new Set(['prod', 'testnet', 'staging']);

const isSessionRecordingEnabled = () => {
  return SESSION_RECORDING_ENVS.has("testnet");
};

const isMaskedInputEnabled = () => {
  return false;
};

const setup = () => {
  if ("true" === 'true') setupSentry();
  if ("true" === 'true') setupDatadog();
  console.info(`Paradex Webapp ver. ${"testnet@1.89.4-cb2ba4660bdb"}`); // eslint-disable-line no-console
};

const setupSentry = () => {
  Sentry.init({
    dsn: "https://1747b11cd4234ea5a44d1d0c1ffc76dc@o169143.ingest.sentry.io/4504532046381056",
    environment: "testnet",
    release: "testnet@1.89.4-cb2ba4660bdb",
    attachStacktrace: true,
    // Only enabled for sessions with errors to keep costs down
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: isSessionRecordingEnabled() ? 1 : 0,
    beforeSend(event, hint) {
      event.extra = { ...event.extra, viewType: getAppViewType() };

      const error = hint.originalException;
      const rootCause = getRootCause(error);

      // Aggressively group "Failed to fetch" errors as most are not actionable.
      // Keep ingesting so that we have a history of these errors.
      if (/Failed to fetch/iu.exec(getErrorMessage(rootCause)) != null) {
        event.fingerprint = ['failed-to-fetch'];
        return event;
      }

      // Ignore WebSocket errors without request metadata as these are only relevant in Datadog
      if (
        /Unexpected WebSocket response without request metadata/iu.exec(
          getErrorMessage(error),
        ) != null
      ) {
        return null;
      }

      return event;
    },
    integrations: [
      Sentry.replayIntegration({
        maskAllInputs: isMaskedInputEnabled(),
        maskAllText: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
  });
};

const setupDatadog = () => {
  const SITE = 'datadoghq.com';
  /** Allows DD to run when Paradex runs in an iframe */
  const USE_CROSS_SITE_SESSION_COOKIE = true;

  datadogLogs.init({
    clientToken: "pub85e5ec7587a51ce9bf31848163b6e363",
    site: SITE,
    service: "paradex-web",
    env: "testnet",
    version: "testnet@1.89.4-cb2ba4660bdb",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    useCrossSiteSessionCookie: USE_CROSS_SITE_SESSION_COOKIE,
  });
  datadogLogs.setGlobalContextProperty('viewType', getAppViewType());
  datadogRum.init({
    applicationId: "340307cc-5df0-421e-8845-d69e9bd24444",
    clientToken: "pub85e5ec7587a51ce9bf31848163b6e363",
    site: SITE,
    service: "paradex-web",
    env: "testnet",
    beforeSend(event, context) {
      enrichPostOrderReq(event, context);
      enrichActionWithCustomAttributes(event, context);
      return true;
    },
    version: "testnet@1.89.4-cb2ba4660bdb",
    sessionSampleRate: 100,
    sessionReplaySampleRate: isSessionRecordingEnabled() ? 100 : 0,
    trackUserInteractions: true,
    allowedTracingUrls: ["https://api.testnet.paradex.trade/v1", "wss://ws.api.testnet.paradex.trade/v1"],
    defaultPrivacyLevel: isMaskedInputEnabled() ? 'mask-user-input' : 'allow',
    trackResources: true,
    trackLongTasks: true,
    useCrossSiteSessionCookie: USE_CROSS_SITE_SESSION_COOKIE,
  });
  datadogRum.setGlobalContextProperty('viewType', getAppViewType());
  datadogRum.startSessionReplayRecording();
};

interface ExceptionLogger {
  (error: unknown, details?: Record<string, unknown>): void;
}

const logException: ExceptionLogger = (error, details) => {
  if ("testnet" === 'test-runner') {
    throw error;
  }

  if ("true" === 'true') {
    logDataDogException(error, details);
  }

  if ("true" === 'true') {
    logSentryException(error, details);
  }

  if ("production" === 'development' || "production" === 'production') {
    logConsoleException(error, details); // In addition to sending logs to sentry/dd, log errors to console to simplify local debugging and enable some of DataDog features (e.g. Error Click detection)
  }
};

const logEvent = (message: string, details?: Record<string, unknown>) => {
  if ("testnet" === 'test-runner') {
    return;
  }

  if ("true" === 'true') {
    datadogLogs.logger.info(message, details);
  }

  if ("true" === 'true') {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message,
      data: details,
      timestamp: Date.now() / 1000,
    });
  }

  if ("production" === 'development' || String("https://app.testnet.paradex.trade").includes('localhost')) {
    // eslint-disable-next-line no-console
    console.info(message, details);
  }
};

const logSentryException: ExceptionLogger = (error, details = {}) => {
  Sentry.captureException(error, { extra: details });
};

const logDataDogException: ExceptionLogger = (error, details = {}) => {
  datadogLogs.logger.error('Exception', details, error as Error | undefined);
};

const logConsoleException: ExceptionLogger = (error, details = {}) => {
  const errorMessage = getErrorMessageWithCauses(error);
  // eslint-disable-next-line no-console
  console.error(
    `%c${errorMessage}`,
    'border-left: 4px solid #3498db; padding-left: 8px;',
    { details },
  );
};
interface User {
  id: string;
  starknetAddress: Nullable<string>;
  [key: string]: unknown;
}

const addAction = (name: string, context?: object | undefined) => {
  datadogRum.addAction(name, context);
};

const setUser = (user: User) => {
  // Set property individually to avoid overwriting the user object
  for (const [key, value] of Object.entries(user)) {
    setUserProperty(key, value);
  }
  Sentry.setUser(user);
};

const setUserProperty = (key: string, value: unknown) => {
  datadogRum.setUserProperty(key, value);
  datadogLogs.setUserProperty(key, value);
};

const clearUser = () => {
  datadogRum.clearUser();
  datadogLogs.clearUser();
  Sentry.setUser(null);
};

export {
  setup,
  logException,
  logEvent,
  setUser,
  clearUser,
  setUserProperty,
  addAction,
};
