import { useCallback, useEffect, useRef, useState } from 'react';

import { getAuthToken } from '#/api/auth';

import { userUnauthorizedTopic } from '#/features/auth/auth-topics';
import { translator } from '#/features/localization/utils';
import { logException } from '#/features/logging/logging';

import { isAbortError } from '#/utils/errors';

export interface BaseReq {
  readonly signal?: AbortSignal;
  readonly body?: unknown;
  readonly headers?: Record<string, string>;
}

export interface BasePaginatedReq extends BaseReq {
  readonly cursor?: string | null;
  readonly page_size?: number;
}

interface BaseResp<T> {
  readonly ok: boolean;
  readonly status: number;
  readonly data: T;
}

export interface SuccessResp<T> extends BaseResp<T> {
  readonly ok: true;
}

// WIP default type for failed response data T to be defined
export interface FailedResp<T = unknown> extends BaseResp<T> {
  readonly error: Error;
  /** User friendly message to be displayed when needed */
  readonly message: string;
  readonly ok: false;
}

export type Resp<S, F = unknown> = SuccessResp<S> | FailedResp<F>;

export type AsyncResp<S, F = unknown> = Promise<Resp<S, F>>;

export interface PaginatedRespData<T> {
  readonly prev: string | null;
  readonly next: string | null;
  readonly results: readonly T[];
}

export type ApiFn<R extends BaseReq, SuccessData, FailedData = unknown> = (
  req: R,
) => AsyncResp<SuccessData, FailedData>;

export interface ApiState<T> {
  readonly isLoading: boolean;
  readonly resp: Resp<T> | null;
}

export interface ApiCall<T = unknown> {
  /**
   * Avoid using this as you will have to handle the _AbortError_.
   * Prefer using the callback on the execute function.
   */
  readonly promise: AsyncResp<T>;
  readonly abort: () => void;
}

const INITIAL_STATE = {
  isLoading: false,
  resp: null,
};

export function useApi<R extends BaseReq, SuccessData, FailedData>(
  api: ApiFn<R, SuccessData, FailedData>,
  initialState: ApiState<SuccessData> = INITIAL_STATE,
): [
  state: ApiState<SuccessData>,
  execute: (
    req: R,
    cb?: (resp: Resp<SuccessData, FailedData>) => void,
  ) => ApiCall<SuccessData>,
] {
  const abortControllerSetRef = useRef<Set<AbortController>>();

  useEffect(
    () => () => {
      const abortControllerSet = abortControllerSetRef.current;
      if (abortControllerSet == null) return;
      for (const abortController of abortControllerSet) {
        abortController.abort();
      }
    },
    [],
  );

  const [state, setState] = useState(initialState);

  const exec = useCallback(
    (
      req: R,
      cb?: (resp: Resp<SuccessData, FailedData>) => void,
    ): ApiCall<SuccessData> => {
      setState((currState) => ({ ...currState, isLoading: true }));

      let finalReq: R;
      let abortController: AbortController | undefined;

      // only create a controller when caller is not using its own signal
      if (req.signal == null) {
        abortController = new AbortController();
        abortControllerSetRef.current ??= new Set();
        abortControllerSetRef.current.add(abortController);
        finalReq = { ...req, signal: abortController.signal };
      } else {
        finalReq = req;
      }

      const callApi = async () => {
        try {
          const resp = await api(finalReq);
          // skip side effects in case the request was aborted
          if (resp.ok || !isAbortError(resp.error)) {
            cb?.(resp);
            setState({ isLoading: false, resp });
          }
          return resp;
        } finally {
          // remove abort controller from set so we don't leak memory
          if (abortController != null)
            abortControllerSetRef.current?.delete(abortController);
        }
      };

      return {
        promise: callApi(),
        abort: () => {
          if (abortController == null) {
            throw new Error(
              "You've provided your own signal, use your abort controller to cancel.",
            );
          }
          abortController.abort();
        },
      };
    },
    [api],
  );

  return [state, exec];
}

export interface RequestParams extends BaseReq {
  readonly url: string;
  readonly method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'HEAD' | 'PUT';
  readonly auth?: boolean;
}

export async function requestApi<SuccessData, FailedData = unknown>(
  params: RequestParams,
): AsyncResp<SuccessData, FailedData> {
  const resp = await requestInternal<SuccessData, FailedData>("https://api.testnet.paradex.trade/v1", params);

  authListener(resp);

  return resp;
}

function authListener<T>(response: Resp<T>) {
  const isUnauthorized =
    // `401` when common API request like `GET /positions` fails due to expired JWT
    response.status === 401;

  if (isUnauthorized) {
    userUnauthorizedTopic.publish({});
  }
}

async function parseJson(resp: Response): Promise<unknown> {
  const contentType = resp.headers.get('content-type');
  if (contentType == null) return null;
  return contentType.includes('application/json') ? resp.json() : null;
}

const BASE_METABASE_URL = 'https://tradeparadigm.metabaseapp.com';

export async function requestMetabase<SuccessData, FailedData = unknown>(
  params: RequestParams,
): AsyncResp<SuccessData, FailedData> {
  return requestInternal(BASE_METABASE_URL, { ...params, auth: false });
}

async function requestInternal<SuccessData, FailedData = unknown>(
  baseUrl: string,
  params: RequestParams,
): AsyncResp<SuccessData, FailedData> {
  const { url, method, headers, body, signal, auth = true } = params;
  const authToken = getAuthToken();

  const baseHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    ...(authToken !== '' &&
      auth && {
        Authorization: `Bearer ${authToken}`,
      }),
  };

  let resp;
  try {
    resp = await fetch(`${baseUrl}${url}`, {
      method,
      headers: {
        ...baseHeaders,
        ...headers,
      },
      signal,
      body: JSON.stringify(body),
      credentials: 'omit',
    });
  } catch (_error) {
    const cause = _error as Error;
    const error = new Error(
      `Failed to connect to Paradex servers for '${method} ${url}'`,
      { cause },
    );

    return {
      ok: false,
      status: 0,
      error,
      message: error.message,
      data: {} as FailedData,
    };
  }

  let data: unknown;
  try {
    data = await parseJson(resp);
  } catch (_error) {
    const cause = _error as Error;

    if (cause.name === 'SyntaxError') {
      // eslint-disable-next-line no-console
      console.error({
        message: 'Failed parsing API response as JSON',
        data: { method, url, status: resp.status },
        error: cause,
      });
    }

    const error = new Error(
      `Failed parsing API response for '${method} ${url}'`,
      { cause },
    );

    return {
      ok: false,
      status: resp.status,
      error,
      message: error.message,
      data: {} as FailedData,
    };
  }

  if (!resp.ok) {
    const message = (function getFailedRequestMessage() {
      if (data == null) return null;
      if ([400, 401].includes(resp.status)) {
        const apiErrorMessage = getApiErrorMessage(data);
        if (apiErrorMessage != null) return apiErrorMessage;
      }
      return JSON.stringify(data);
    })();

    const error = new Error(
      `Request '${method} ${url}' failed with status ${resp.status}`,
      {
        cause: message,
      },
    );

    return {
      ok: false,
      status: resp.status,
      error,
      message: message ?? error.message,
      data: data as FailedData,
    };
  }

  return {
    ok: true,
    status: resp.status,
    data: data as SuccessData,
  };
}

interface ApiErrorResponseBody {
  readonly error: ApiErrorCode;
  readonly message: string;
}

/** @source https://docs.api.prod.paradex.trade/#tocS_responses.ErrorCode */
export type ApiErrorCode =
  | 'VALIDATION_ERROR'
  | 'BINDING_ERROR'
  | 'INTERNAL_ERROR'
  | 'NOT_FOUND'
  | 'SERVICE_UNAVAILABLE'
  | 'INVALID_REQUEST_PARAMETER'
  | 'ORDER_ID_NOT_FOUND'
  | 'ORDER_IS_CLOSED'
  | 'ORDER_IS_NOT_OPEN_YET'
  | 'CLIENT_ORDER_ID_NOT_FOUND'
  | 'DUPLICATED_CLIENT_ID'
  | 'INVALID_PRICE_PRECISION'
  | 'INVALID_TOKEN'
  | 'INVALID_ETHEREUM_ADDRESS'
  | 'INVALID_ETHEREUM_SIGNATURE'
  | 'INVALID_STARKNET_ADDRESS'
  | 'INVALID_STARKNET_SIGNATURE'
  | 'STARKNET_SIGNATURE_VERIFICATION_FAILED'
  | 'BAD_STARKNET_REQUEST'
  | 'ETHEREUM_SIGNER_MISMATCH'
  | 'ETHEREUM_HASH_MISMATCH'
  | 'NOT_ONBOARDED'
  | 'INVALID_TIMESTAMP'
  | 'INVALID_SIGNATURE_EXPIRATION'
  | 'ACCOUNT_NOT_FOUND'
  | 'INVALID_ORDER_SIGNATURE'
  | 'PUBLIC_KEY_INVALID'
  | 'UNAUTHORIZED_ETHEREUM_ADDRESS'
  | 'ETHEREUM_ADDRESS_ALREADY_ONBOARDED'
  | 'MARKET_NOT_FOUND'
  | 'ALLOWLIST_ENTRY_NOT_FOUND'
  | 'USERNAME_IN_USE'
  | 'GEO_IP_BLOCK'
  | 'ETHEREUM_ADDRESS_BLOCKED'
  | 'VAULT_NOT_FOUND';

/**
 * Gets the error message for an API error response.
 * Order of precedence:
 *   1. If the error is mapped, use the mapped error.
 *   2. Otherwise, use the `message` from the response.
 *   3. Otherwise, return `null`;
 */
function getApiErrorMessage(data: unknown): string | null {
  if (isApiErrorResponse(data)) {
    const mappedError = errorCodeToMessage(data.error);
    if (mappedError != null) return mappedError;
    return data.message;
  }
  return null;
}

/**
 * Errors from the API are expected to abide to `ApiErrorResponseBody` interface.
 * The API is capable of returning this structure for multiple response status,
 * including but not limited to 400, 401, 400, 500, 503. It's not guaranteed
 * that the error will be in this format for all instances of such responses.
 */
function isApiErrorResponse(data: unknown): data is ApiErrorResponseBody {
  const apiErrorData = data as Partial<ApiErrorResponseBody>;
  return (
    typeof apiErrorData === 'object' &&
    apiErrorData.error != null &&
    apiErrorData.message != null
  );
}

function errorCodeToMessage(errorCode: string) {
  if (errorCode === '') {
    return null;
  }
  if (!isKnownError(errorCode)) {
    logException(
      new Error(`Missing translation for API Error, errorCode='${errorCode}'`),
    );
    return null;
  }
  if (EXCLUDED_FROM_TRANSLATION.has(errorCode)) {
    return null;
  }

  const knownReason = ERROR_MESSAGE_MAP[errorCode];
  return translator.t(knownReason);
}

const EXCLUDED_FROM_TRANSLATION = new Set<ApiErrorCode>([
  'INVALID_REQUEST_PARAMETER',
]);

function isKnownError(errorCode: string): errorCode is ApiErrorCode {
  return errorCode in (ERROR_MESSAGE_MAP as Record<string, string>);
}

export const ERROR_MESSAGE_MAP = {
  VALIDATION_ERROR: 'Validation Error',
  BINDING_ERROR: 'Binding Error',
  INTERNAL_ERROR: 'Internal Error',
  NOT_FOUND: 'Not Found',
  SERVICE_UNAVAILABLE: 'Service Unavailable',
  INVALID_REQUEST_PARAMETER: 'Invalid Request Parameter',
  ORDER_ID_NOT_FOUND: 'Order Id Not Found',
  ORDER_IS_CLOSED: 'Order Is Closed',
  ORDER_IS_NOT_OPEN_YET: 'Order Is Not Open Yet',
  CLIENT_ORDER_ID_NOT_FOUND: 'Client Order Id Not Found',
  DUPLICATED_CLIENT_ID: 'Duplicated Client Id',
  INVALID_PRICE_PRECISION: 'Invalid Price Precision',
  INVALID_TOKEN: 'Invalid Token',
  INVALID_ETHEREUM_ADDRESS: 'Invalid Ethereum Address',
  INVALID_ETHEREUM_SIGNATURE: 'Invalid Ethereum Signature',
  INVALID_STARKNET_ADDRESS: 'Invalid Starknet Address',
  INVALID_STARKNET_SIGNATURE: 'Invalid Starknet Signature',
  STARKNET_SIGNATURE_VERIFICATION_FAILED:
    'Starknet Signature Verification Failed',
  BAD_STARKNET_REQUEST: 'Bad Starknet Request',
  ETHEREUM_SIGNER_MISMATCH: 'Ethereum Signer Mismatch',
  ETHEREUM_HASH_MISMATCH: 'Ethereum Hash Mismatch',
  NOT_ONBOARDED: 'Not Onboarded',
  INVALID_TIMESTAMP: 'Invalid Timestamp',
  INVALID_SIGNATURE_EXPIRATION: 'Invalid Signature Expiration',
  ACCOUNT_NOT_FOUND: 'Account Not Found',
  INVALID_ORDER_SIGNATURE: 'Invalid Order Signature',
  PUBLIC_KEY_INVALID: 'Public Key Invalid',
  UNAUTHORIZED_ETHEREUM_ADDRESS: 'Unauthorized Ethereum Address',
  ETHEREUM_ADDRESS_ALREADY_ONBOARDED: 'Ethereum Address Already Onboarded',
  MARKET_NOT_FOUND: 'Market Not Found',
  ALLOWLIST_ENTRY_NOT_FOUND: 'Allowlist Entry Not Found',
  USERNAME_IN_USE:
    'This username already exists. Please choose a different username and try again.',
  GEO_IP_BLOCK: 'Service access restricted in your region',
  ETHEREUM_ADDRESS_BLOCKED: 'Blocked Ethereum Address',
  VAULT_NOT_FOUND: 'Vault not found',
  SOCIAL_USERNAME_IN_USE:
    'This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.',
} as const;
