export const REFETCH_INTERVAL = 10_000;

export const PROTOCOL_VAULT_ADDRESS = (() => {
  switch ("testnet") {
    case 'prod':
      return '0x5f43c92dbe4e995115f351254407e7e84abf04cbe32a536345b9d6c36bc750f';
    case 'testnet':
      return '0x2ef61f9e11bd3ab01ac0658daafe53567f4d2c650347c67b99f7a7e1d4c6f2';
    case 'nightly':
      return '0x63aa320646286d250956f136f0854528a863ffd7bb9aac534aded66418e3f97';
    default:
      return null;
  }
})();
