import { Maybe } from 'yup';

import { logException } from '#/features/logging/logging';

type Input = Maybe<Record<string, unknown> | object>;
type Output = Maybe<Record<string, unknown> | object>;

/**
 * @returns A new object with a single level of depth. If failed to flatten the object, it returns the input object.
 */
export default function flatObject(input: Input): Output {
  if (input == null) return input;

  try {
    const output = {};
    flatten(output, input);
    return output;
  } catch (err) {
    const message = 'Failed to flatten the object';
    if ("testnet" !== 'test-runner') {
      logException(new Error(message, { cause: err }), { inputObject: input });
    }
    return input;
  }
}

function flatten(output: Record<string, unknown>, val: unknown) {
  if (val == null) return;

  if (typeof val !== 'object') {
    throw new Error('Input must be an object');
  }

  if (Array.isArray(val)) {
    throw new Error('Arrays are not supported');
  }

  for (const key in val) {
    if (Object.hasOwn(val, key)) {
      const value = (val as Record<string, unknown>)[key];

      if (Array.isArray(value)) {
        throw new Error('Nested arrays are not supported');
      }

      if (Object.hasOwn(output, key)) {
        throw new Error(`Duplicated key='${key}' in flattened object`);
      }

      if (value === null || value === undefined) {
        output[key] = 'undefined';
      } else if (typeof value === 'object') {
        flatten(output, value);
      } else {
        output[key] = value;
      }
    }
  }
}
