import {
  Account,
  AccountProfile,
  processAccount,
  processAccountProfile,
  RawAccount,
  RawAccountProfile,
} from '#/api/account';
import { processBbo } from '#/api/bbo';
import { Fill, processFill, RawFill } from '#/api/fills';
import {
  FundingData,
  processFundingData,
  RawFundingData,
} from '#/api/funding-data';
import {
  FundingPayment,
  processFundingPayment,
  RawFundingPayment,
} from '#/api/funding-payments';
import {
  FundingRateComparison,
  processFundingRateComparison,
  RawFundingRateComparison,
} from '#/api/funding-rate-comparison';
import {
  MarketSummary,
  processMarketSummary,
  RawMarketSummary,
} from '#/api/markets-summary';
import {
  OrderBookUpdate,
  processOrderBookUpdate,
  RawOrderBookUpdate,
} from '#/api/order-book';
import { Order, processOrder, RawOrder } from '#/api/orders';
import { Position, processPosition, RawPosition } from '#/api/positions';
import { processTradeBust, RawTradeBust, TradeBust } from '#/api/trade-busts';
import { processTrade, RawTrade, Trade } from '#/api/trades';
import {
  processTransaction,
  RawTransaction,
  Transaction,
} from '#/api/transactions';
import { processTransfer, RawTransfer, Transfer } from '#/api/transfers';

import * as DebugMonitor from '#/features/debug/DebugMonitor';
import { FeatureFlags } from '#/features/feature-flags';
import { logEvent } from '#/features/logging/logging';

import getQueryString from '#/utils/getQueryString';

import createWebSocket, {
  ApiWebSocket,
  AsyncWSResp,
  WSNotification,
  WSReqParams,
} from './createWebSocket';
import { throttleOrderBookUpdate } from './orderbook-throttle';

import type { Bbo, RawBbo } from '#/api/bbo';

export function createParadexWebSocket(): ParadexWebSocket {
  return createWebSocket({
    urlProvider,
    processNotification,
  });
}

async function urlProvider() {
  const query = getQueryString([['cancel-on-disconnect', false]]);
  return `${"wss://ws.api.testnet.paradex.trade/v1"}${query}`;
}

export interface ParadexWebSocket
  extends Omit<ApiWebSocket<ParadexNotification>, 'request'> {
  readonly request: ParadexWebSocketRequest;
}

interface ParadexWebSocketRequest {
  (req: AuthReq): AsyncWSResp<AuthResult>;
  (req: SubscribeReq): AsyncWSResp<SubscribeResult>;
  (req: UnsubscribeReq): AsyncWSResp<UnsubscribeResult>;
}

interface AuthReq extends WSReqParams {
  readonly method: 'auth';
  readonly params: {
    readonly bearer: string;
  };
}

type AuthResult = Record<string, never>;
interface SubscribeReq extends WSReqParams {
  readonly method: 'subscribe';
  readonly params: {
    readonly channel: string;
    readonly data?: unknown;
  };
}

type SubscribeResult = readonly string[];

interface UnsubscribeReq extends WSReqParams {
  readonly method: 'unsubscribe';
  readonly params: {
    readonly channel: string;
  };
}

type UnsubscribeResult = readonly string[];

const KNOWN_TOPICS = [
  'account',
  'account.profile',
  'bbo',
  'trades',
  'order_book',
  'orders',
  'positions',
  'fills',
  'funding_payments',
  'funding_data',
  'markets_summary',
  'tradebusts',
  'transaction',
  'transfers',
  'funding_rate_comparison',
] as const;

type KnownTopic = (typeof KNOWN_TOPICS)[number];

export type ParadexTopic = KnownTopic | 'unknown';

interface ChannelDataByTopic {
  readonly account: Account;
  readonly 'account.profile': AccountProfile;
  readonly bbo: Bbo;
  readonly trades: Trade;
  readonly order_book: OrderBookUpdate;
  readonly orders: Order;
  readonly positions: Position;
  readonly funding_payments: FundingPayment;
  readonly funding_data: FundingData;
  readonly fills: Fill;
  readonly markets_summary: MarketSummary;
  readonly tradebusts: TradeBust;
  readonly transaction: Transaction;
  readonly transfers: Transfer;
  readonly funding_rate_comparison: FundingRateComparison;
  readonly unknown: unknown;
}

export type ParadexNotificationByTopic = {
  readonly [Topic in ParadexTopic]: {
    readonly channel: string;
    readonly topic: Topic;
    readonly data: ChannelDataByTopic[Topic];
  };
};

export type ParadexNotification = ParadexNotificationByTopic[ParadexTopic];

export type AccountNotification = ParadexNotificationByTopic['account'];
export type AccountProfileNotification =
  ParadexNotificationByTopic['account.profile'];
export type BboNotification = ParadexNotificationByTopic['bbo'];
export type TradeNotification = ParadexNotificationByTopic['trades'];
export type OrderBookNotification = ParadexNotificationByTopic['order_book'];
export type OrderNotification = ParadexNotificationByTopic['orders'];
export type PositionNotification = ParadexNotificationByTopic['positions'];
export type FundingPaymentNotification =
  ParadexNotificationByTopic['funding_payments'];
export type FundingDataNotification =
  ParadexNotificationByTopic['funding_data'];
export type MarketSummaryNotification =
  ParadexNotificationByTopic['markets_summary'];
export type FillNotification = ParadexNotificationByTopic['fills'];
export type TradeBustNotification = ParadexNotificationByTopic['tradebusts'];
export type TransactionNotification = ParadexNotificationByTopic['transaction'];
export type TransferNotification = ParadexNotificationByTopic['transfers'];
export type FundingRateComparisonNotification =
  ParadexNotificationByTopic['funding_rate_comparison'];

function getParadexChannelTopic(channel: string): ParadexTopic {
  const [topic] = channel.split('.');
  if (topic != null && isKnownTopic(topic)) return topic;
  return 'unknown';
}

function isKnownTopic(str: string): str is KnownTopic {
  return (KNOWN_TOPICS as readonly string[]).includes(str);
}

const isUsingSnapshotsThrottling = FeatureFlags.getBooleanValue(
  'order-book-snapshots-throttling-enabled',
  false,
);

function processNotification(
  raw: WSNotification,
): ParadexNotification | 'skip' {
  const { channel, data } = raw.params;
  const topic = getParadexChannelTopic(channel);

  switch (topic) {
    case 'account':
      return {
        channel,
        topic,
        data: processAccount(data as RawAccount),
      };

    case 'account.profile':
      return {
        channel,
        topic,
        data: processAccountProfile(data as RawAccountProfile),
      };

    case 'bbo':
      return {
        channel,
        topic,
        data: processBbo(data as RawBbo),
      };

    case 'trades':
      return {
        channel,
        topic,
        data: processTrade(data as RawTrade),
      };

    case 'order_book':
      DebugMonitor.pushValue('order_book.last_updated_at', () =>
        String(Date.now() - (data as RawOrderBookUpdate).last_updated_at),
      );

      if (
        isUsingSnapshotsThrottling &&
        throttleOrderBookUpdate(data as RawOrderBookUpdate)
      ) {
        return 'skip';
      }

      return {
        channel,
        topic,
        data: processOrderBookUpdate(data as RawOrderBookUpdate),
      };

    case 'orders':
      logEvent(`Received WS notification for channel='${channel}'`, {
        channel,
        receivedAt: Date.now(),
        data,
      });
      return {
        channel,
        topic,
        data: processOrder(data as RawOrder),
      };

    case 'positions':
      return {
        channel,
        topic,
        data: processPosition(data as RawPosition),
      };

    case 'fills':
      return {
        channel,
        topic,
        data: processFill(data as RawFill),
      };

    case 'funding_payments':
      return {
        channel,
        topic,
        data: processFundingPayment(data as RawFundingPayment),
      };

    case 'funding_rate_comparison':
      return {
        channel,
        topic,
        data: processFundingRateComparison(data as RawFundingRateComparison),
      };

    case 'funding_data':
      return {
        channel,
        topic,
        data: processFundingData(data as RawFundingData),
      };

    case 'markets_summary':
      DebugMonitor.pushValue('markets_summary.created_at', () =>
        String(Date.now() - (data as RawMarketSummary).created_at),
      );
      return {
        channel,
        topic,
        data: processMarketSummary(data as RawMarketSummary),
      };

    case 'tradebusts':
      return {
        channel,
        topic,
        data: processTradeBust(data as RawTradeBust),
      };

    case 'transaction':
      return {
        channel,
        topic,
        data: processTransaction(data as RawTransaction),
      };

    case 'transfers':
      return {
        channel,
        topic,
        data: processTransfer(data as RawTransfer),
      };

    case 'unknown':
      return {
        channel,
        topic,
        data,
      };

    // no default
  }
}
