export type Env = 'local' | 'nightly' | 'staging' | 'testnet' | 'prod';

/**
 * Retrieves the value associated with the current environment from
 * the provided data object. If no value is found for the current
 * environment, it falls back to the fallback value.
 *
 * This is useful for big objects that have different values for
 * different environments, where maintaining a separate object for
 * each environment is too cumbersome.
 *
 * Data provided for the 'local' environment is used for both 'local'
 * and 'test' environments.
 *
 * @param dataByEnv - An object containing values for different
 *  environments and an optional fallback value.
 * @returns The value associated with the current environment or the
 *  fallback value.
 * @throws An error if no value is found for the current environment
 *  and no fallback value is provided.
 * @example
 *   const data = perEnv({
 *     local: 'local value',
 *     testnet: 'testnet value',
 *     prod: 'prod value',
 *     fallback: 'fallback value',
 *   });
 */
export function perEnv<T, U = undefined>(dataByEnv: {
  readonly [K in Env | 'fallback']?: T;
}): NonNullable<T> | NonNullable<U> {
  if (isLocalEnv() && dataByEnv.local != null) {
    return dataByEnv.local;
  }

  if (isTestEnv() && dataByEnv.local != null) {
    return dataByEnv.local;
  }

  const data = dataByEnv["testnet" as Env];
  if (data != null) return data;

  if (dataByEnv.fallback != null) {
    return dataByEnv.fallback;
  }

  throw new Error(`No data found for environment ${"testnet"}`);
}

export function isLocalEnv(): boolean {
  return String("https://app.testnet.paradex.trade").includes('localhost');
}

export function isTestEnv(): boolean {
  if ("false" === 'true') return true;
  if ("testnet" === 'test-runner') return true;
  return false;
}
