import { isLocalEnv, isTestEnv } from '#/features/env';

import type { Env } from '#/features/env';

export type Flag =
  | 'marketing-campaign-enabled'
  | 'deposit-single-step-enabled'
  | 'hide-account-usernames-enabled'
  | 'order-book-snapshots-throttling-enabled'
  | 'logging-websocket-request-response-latencies-enabled'
  | 'logging-websocket-timeout-latencies-enabled'
  | 'scaled-orders-enabled'
  | 'layerswap-enabled'
  | 'rhinofi-enabled'
  | 'sub-accounts-enabled'
  | 'pro-league-enabled'
  | 'share-card-enabled'
  | 'transfer-asset-enabled'
  | 'twitter-integration-enabled'
  | 'submit-derivation-path-during-onboarding'
  | 'pwa-install-guide-popup'
  | 'tp-sl-order-builder-enabled'
  | 'tp-sl-order-builder-stop-order-enabled'
  | 'tp-sl-position-enabled'
  | 'order-book-tick-size-aggregation-enabled'
  | 'portfolio-v2-enabled'
  | 'l2-only-session-enabled'
  | 'vault-trade-history-enabled';

const FEATURE_FLAGS: Record<Flag, Record<Env, unknown>> = {
  'marketing-campaign-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'deposit-single-step-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'hide-account-usernames-enabled': {
    local: false,
    nightly: false,
    staging: false,
    testnet: false,
    prod: false,
  },

  'order-book-snapshots-throttling-enabled': {
    local: false,
    nightly: false,
    staging: false,
    testnet: false,
    prod: false,
  },
  /**
   * Log all the WS Request<>Response latencies as seen by the user
   * Note: Only WS messages with `id` property are considered a request, response;
   *       Averaged out volume per user is expected to be low;
   *       But if unstable network conditions some user may produce a spike in log volume;
   */
  'logging-websocket-request-response-latencies-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  /**
   * Log all the WS Request<>Response timeout latencies as seen by the user
   * Note: This is expected to be a much lower average volume log than all the WS Request<>Response latencies,
   *       so kept in separate flag as should be enabled most of the time on `testnet`/`prod` envs;
   */
  'logging-websocket-timeout-latencies-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'scaled-orders-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'layerswap-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'rhinofi-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: false, // Should not be enabled on testnet by Rhino's request
    prod: true,
  },
  'sub-accounts-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'pro-league-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: false,
    prod: false,
  },
  'share-card-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'transfer-asset-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'twitter-integration-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'submit-derivation-path-during-onboarding': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'pwa-install-guide-popup': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'tp-sl-order-builder-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'tp-sl-order-builder-stop-order-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'tp-sl-position-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'order-book-tick-size-aggregation-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'portfolio-v2-enabled': {
    local: false,
    nightly: false,
    staging: false,
    testnet: false,
    prod: false,
  },
  'l2-only-session-enabled': {
    local: true,
    nightly: true,
    staging: true,
    testnet: true,
    prod: true,
  },
  'vault-trade-history-enabled': {
    local: false,
    nightly: false,
    staging: false,
    testnet: false,
    prod: false,
  },
};

const FeatureFlags = {
  getBooleanValue(flag: Flag, defaultValue: boolean): boolean {
    if (isTestEnv()) return true;
    return Boolean(getValue(flag, defaultValue));
  },

  /**
   * For feature flags that can be overridden by URL parameters.
   *  This is only intended for pre-launch verification of features on Public environments.
   *
   * @example `/portfolio?sub-accounts-enabled&bridging-enabled`
   * @example `/portfolio?sub-accounts-enabled=true&bridging-enabled=false`
   */
  getOverridableBooleanValue(flag: Flag, defaultValue: boolean): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const urlOverride = urlParams.get(flag);
    if (urlOverride == null) {
      return this.getBooleanValue(flag, defaultValue);
    }
    if (!['', 'true', 'false'].includes(urlOverride)) {
      return this.getBooleanValue(flag, defaultValue);
    }

    if (urlOverride === 'false') return false;
    return true;
  },
};

function getValue(flag: Flag, defaultValue: unknown): unknown {
  if (isLocalEnv()) return FEATURE_FLAGS[flag].local;
  return FEATURE_FLAGS[flag]["testnet" as Env] ?? defaultValue;
}

export { FeatureFlags };
