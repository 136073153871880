import BigNumber from 'bignumber.js';

import { getChainGas } from '#/api/chain-gas';

import { logEvent, logException } from '#/features/logging/logging';
import { flatErrorMessage } from '#/features/logging/utils';
import { BridgedToken } from '#/features/paraclear';
import { debugParadexTx } from '#/features/paradex-chain';
import { prepareErrorMessage } from '#/features/wallet/ethereum';

import * as LayerswapService from './services/layerswap-service';
import * as ParaclearService from './services/paraclear-service';

import type { SystemConfigView } from '#/features/system/system-config-context';
import type { WalletState } from '#/features/wallet/wallet-context';
import type { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import type { AsyncResult } from '#/utils/types';

export interface WithdrawService {
  initiateWithdraw(
    amount: string,
    asset: string,
    isAutoWithdraw: boolean,
    withdrawFee: BigNumber,
  ): AsyncResult<void>;

  fetchWithdrawFee(
    bridge: string,
    amount: string,
    sourceChain: string,
    destinationChain: string,
  ): AsyncResult<{ readonly fee: BigNumber }>;

  withdrawViaLayerswap(
    token: BridgedToken,
    amount: BigNumber,
    sourceChain: string,
    destinationChain: string,
  ): AsyncResult<void>;
}

export function createService(
  walletState: WalletState,
  paraclearProvider: ParaclearProvider,
  systemConfig: SystemConfigView,
): WithdrawService {
  return {
    async initiateWithdraw(
      amount: string,
      asset: string,
      isAutoWithdraw: boolean,
      withdrawFee: BigNumber,
    ): AsyncResult<void> {
      if (walletState.baseAccount == null) {
        throw new Error('initiateWithdraw called before address is available');
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error('initiateWithdraw called for non Ethereum address');
      }

      const token = systemConfig.getToken(asset);

      try {
        const txHash = await ParaclearService.requestWithdraw(
          new BigNumber(amount),
          walletState.baseAccount.address,
          token,
          systemConfig.paraclear,
          paraclearProvider,
          systemConfig.relayer.l2Address,
          isAutoWithdraw,
          withdrawFee,
        );
        debugParadexTx(
          'Initiate Withdraw',
          systemConfig.starknet.explorerUrl,
          txHash,
        );
      } catch (err) {
        const description = flatErrorMessage('Failed to withdraw funds', err);
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      return { ok: true, data: undefined };
    },

    async fetchWithdrawFee(
      bridge: string,
      amount: string,
      sourceChain: string,
      destinationChain: string,
    ): AsyncResult<{ readonly fee: BigNumber }> {
      const chainGas = await getChainGas({
        source_chain: sourceChain,
        destination_chain: destinationChain,
        bridge,
        amount,
      });

      if (!chainGas.ok) {
        return { ok: false, error: chainGas.error, reason: chainGas.message };
      }

      return { ok: true, data: { fee: BigNumber(chainGas.data.fee_usdc) } };
    },

    async withdrawViaLayerswap(
      token: BridgedToken,
      amount: BigNumber,
      chainId: string,
      destinationAddress: string,
    ): AsyncResult<void> {
      if (walletState.baseAccount == null) {
        throw new Error(
          'withdrawViaLayerswap called before address is available',
        );
      }

      if (walletState.paradexAddress == null) {
        throw new Error(
          'withdrawViaLayerswap called before paradexAddress available',
        );
      }

      if (token.symbol !== 'USDC') {
        throw new Error('Only USDC is supported via Layerswap');
      }

      let response;
      try {
        response = await LayerswapService.submitSwapRequest(
          amount.toString(),
          chainId,
          "PARADEX_TESTNET",
          walletState.paradexAddress,
          destinationAddress,
        );
      } catch (err) {
        const description = 'Failed to submit swap request to Layerswap';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      if (!response.ok) {
        return {
          ok: false,
          error: response.error,
          reason: `Layerswap: ${response.message}`,
        };
      }

      try {
        const txHash = await ParaclearService.withdrawViaLayerswap(
          paraclearProvider,
          response.data.call_data,
        );
        debugParadexTx(
          'Initiate Withdraw',
          systemConfig.starknet.explorerUrl,
          txHash,
        );
      } catch (err) {
        const description = flatErrorMessage('Failed to withdraw funds', err);
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      return { ok: true, data: undefined };
    },
  };
}
